import { Button } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EzForm } from '../../../components/core/ez-form/EzForm';
import { EzInput } from '../../../components/core/ez-input/EzInput';
import { EzModal } from '../../../components/core/ez-modal/EzModal';
import { EzSelect } from '../../../components/core/ez-select/EzSelect';
import { createTargetAction, getAllTargetsAction, updateTargetAction } from '../../../features/targets/targets.actions';
import { useToast } from '../../../hooks/toaster.hook';
import { BaseFormModal } from '../../../om';
import { TargetDTO } from '../../../om/dto/targets/target.dto';
import { EzInputValidator } from '../../../om/enum/ez-input-validator.enum';
import { TargetKind } from '../../../om/enum/target-kind.enum';
import './target-form-modal.scss';

export interface TagetFormModalProps extends BaseFormModal<TargetDTO> {
	isLoading: boolean;
}

export function TargetFormModal({
	itemToEdit,
	modalId,
	onConfirm,
	closeModalCallback,
	isLoading,
	isOpen,
}: TagetFormModalProps) {
	const { t } = useTranslation();
	const initialFormData = {
		startDate: moment().startOf('month'),
		endDate: moment().endOf('month'),
		target: null,
		description: '',
		kind: 0,
	};
	const [isFormValid, setIsFormValid] = useState(true);
	const [formData, setFormData] = useState<any>(initialFormData);
	const dispatch = useDispatch();
	const toaster = useToast();

	useEffect(() => {
		if (itemToEdit) {
			setFormData({
				...itemToEdit,
				startDate: moment(itemToEdit.startDate),
				endDate: moment(itemToEdit.endDate),
			});
		} else setFormData(initialFormData);
	}, [itemToEdit]);

	const modalActions = () => (
		<Button form="target-form" id="confirm" type="submit" className="btn btn-primary w-32" disabled={!isFormValid}>
			{t('COMMON.CONFIRM')}
		</Button>
	);

	const closeModalAndReloadList = async () => {
		await dispatch(getAllTargetsAction());
		closeModalCallback();
	};

	const successCreateCallback = async (result: any) => {
		closeModalAndReloadList();
		setFormData(initialFormData);
		closeModalCallback();
		toaster.success('Target inserito con successo!');
	};

	const getTargetInputBody = () => {
		return {
			...formData,
			startDate: formData.startDate.format('YYYY-MM-DD'),
			endDate: formData.endDate.format('YYYY-MM-DD'),
		};
	};

	const createTarget = async () => {
		const result = await dispatch(createTargetAction(getTargetInputBody()));
		if (result.error) toaster.error(t('TARGETS.ERRORS.GENERIC_CREATE'));
		else successCreateCallback(result.payload);
	};

	const successUpdateCallback = async (result: any) => {
		closeModalAndReloadList();
		setFormData(initialFormData);
		closeModalCallback();
		toaster.success('Target aggiornato con successo!');
	};

	const updateTarget = async () => {
		const result = await dispatch(updateTargetAction({ id: itemToEdit?.id, body: formData }));
		if (result.error) toaster.error(t('TARGETS.ERRORS.GENERIC_UPDATE'));
		else successUpdateCallback(result.payload);
	};

	const submitForm = () => {
		if (itemToEdit) updateTarget();
		else createTarget();
	};

	const onChange = (e: any) => {
		setFormData((prevState: any) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const onDatepickerChange = (name: string, newInputValue: any) => {
		setFormData((prevState: any) => ({
			...prevState,
			[name]: newInputValue,
		}));
	};

	return (
		<EzModal
			id={modalId}
			title={itemToEdit ? 'TARGETS.EDIT_LABEL' : 'TARGETS.NEW_LABEL'}
			actions={modalActions()}
			closeCallback={closeModalCallback}
			isOpen={isOpen}
		>
			<EzForm
				className="flex flex-col gap-6 mt-4"
				formData={formData}
				formName="target-form"
				onValidationChange={setIsFormValid}
				onSubmit={submitForm}
			>
				<div className="flex gap-4">
					<EzInput
						validators={[EzInputValidator.REQUIRED]}
						name="startDate"
						label="Data Inizio"
						type="date"
						value={formData.startDate}
						onChange={(newInputValue) => onDatepickerChange('startDate', newInputValue)}
					/>
					<EzInput
						validators={[EzInputValidator.REQUIRED]}
						name="endDate"
						label="Data Fine"
						type="date"
						value={formData.endDate}
						onChange={(newInputValue) => onDatepickerChange('endDate', newInputValue)}
					/>
				</div>
				<EzInput
					validators={[EzInputValidator.REQUIRED]}
					name="description"
					label="Descrizione"
					placeholder="Descrizione"
					type="text"
					value={formData.description}
					onChange={onChange}
				/>
				<EzSelect
					validators={[EzInputValidator.REQUIRED]}
					label="Tipo"
					name="kind"
					value={formData.kind}
					onChange={onChange}
					options={Object.values(TargetKind)
						.filter((kind) => typeof kind === 'string')
						.map((kind, index) => ({
							value: index,
							description: t(`TARGETS.KIND.${kind.toString()}`),
						}))}
				/>
				<EzInput
					validators={[EzInputValidator.REQUIRED, EzInputValidator.AMOUNT, EzInputValidator.MORE_THAN_ZERO]}
					name="target"
					label="Target"
					placeholder="1.000"
					type="text"
					value={formData?.target || ''}
					onChange={onChange}
				/>
			</EzForm>
		</EzModal>
	);
}
