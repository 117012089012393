import { Autocomplete, Button, TextField } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EzForm } from '../../../components/core/ez-form/EzForm';
import { EzInput } from '../../../components/core/ez-input/EzInput';
import { EzModal } from '../../../components/core/ez-modal/EzModal';
import { EzSuccessFeedback } from '../../../components/core/ez-success-feedback/EzSuccessFeedback';
import { createOrderAction, getAllAction, updateOrderAction } from '../../../features/orders/orders.actions';
import { useToast } from '../../../hooks/toaster.hook';
import { ClientDTO } from '../../../om/dto/clients/client-dto';
import { OrderDTO } from '../../../om/dto/orders/order-dto';
import { EzInputValidator } from '../../../om/enum/ez-input-validator.enum';
import useCurrency from '../../../utils/currency.util';

export interface OrderModalProps {
	isOpen: boolean;
	orderToEdit?: OrderDTO;
	isLoading: boolean;
	clients: ClientDTO[];
	onClose: () => void;
}

export function OrderModal({ orderToEdit, onClose, isOpen, clients }: OrderModalProps) {
	const toaster = useToast();
	const initialFormData = {
		client: null,
		date: moment(),
		amount: null,
	};
	const [formData, setFormData] = useState<any>(initialFormData);
	const [isFormValid, setIsFormValid] = useState(true);
	const [showFeedback, setShowFeedback] = useState(false);
	const [feedbackMessage, setFeedbackMessage] = useState('');
	const [clientResetId, setClientResetId] = useState(1);
	const { formatCurrency } = useCurrency();
	const dispatch = useDispatch();

	useEffect(() => {
		if (orderToEdit) {
			setFormData({
				client: orderToEdit.client,
				date: moment(orderToEdit.date),
				amount: orderToEdit.amount,
			});
		} else setFormData(initialFormData);
	}, [orderToEdit]);

	const onChange = (e: any) => {
		setFormData((prevState: any) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const onDatePickerChange = (e: any, name: string) => {
		setFormData((prevState: any) => ({
			...prevState,
			[name]: e,
		}));
	};

	const onClientChange = (e: any, newInputValue: any) => {
		setFormData((prevState: any) => ({
			...prevState,
			client: clients.find((client) => client?.code === newInputValue?.code),
		}));
	};

	const closeFeedback = (e: any) => {
		e?.preventDefault();
		setShowFeedback(false);
		setFeedbackMessage('');
	};

	const onCloseModal = (e?: any) => {
		e?.preventDefault();
		closeFeedback(e);
		setClientResetId((count) => count + 1);
		onClose();
	};

	const modalActions = () => {
		if (showFeedback)
			return (
				<Button
					variant="contained"
					id="add-another-order"
					type="button"
					color="success"
					disabled={!isFormValid}
					onClick={closeFeedback}
				>
					{t('ORDERS.ADD_ANOTHER_ORDER')}
				</Button>
			);
		return (
			<Button
				variant="contained"
				form="order-form"
				id="confirm"
				type="submit"
				className="btn btn-primary w-32"
				disabled={!isFormValid}
			>
				{t('COMMON.CONFIRM')}
			</Button>
		);
	};

	const onOrderValidationChange = (isFormValid: boolean) => {
		setIsFormValid(isFormValid);
	};

	const successUpdateCallback = async (result: any) => {
		await dispatch(getAllAction());
		setFormData(initialFormData);
		onCloseModal();
		toaster.success('Ordine aggiornato con successo!');
	};

	const updateOrder = async () => {
		const result = await dispatch(updateOrderAction({ id: orderToEdit?.id, body: formData }));
		if (result.error) toaster.error(t('ORDERS.ERRORS.GENERIC_UPDATE'));
		else successUpdateCallback(result.payload);
	};

	const successCreateCallback = async (result: any) => {
		setFeedbackMessage(
			`Ordine di ${formatCurrency(Number(result?.amount))} di ${result?.client?.company.toUpperCase()} del ${moment(
				result?.date
			).format('DD MMMM YYYY')} inserito con successo!`
		);
		await dispatch(getAllAction());
		setShowFeedback(true);
		setFormData(initialFormData);
	};

	const createOrder = async () => {
		const result = await dispatch(createOrderAction(formData));
		if (result.error) toaster.error(t('ORDERS.ERRORS.GENERIC_CREATE'));
		else successCreateCallback(result.payload);
	};

	const submitForm = () => {
		if (orderToEdit) updateOrder();
		else createOrder();
	};

	return (
		<EzModal
			id="order-form-modal"
			isOpen={isOpen}
			title={showFeedback ? '' : orderToEdit ? 'ORDERS.EDIT_LABEL' : 'ORDERS.NEW_LABEL'}
			actions={modalActions()}
			closeCallback={onCloseModal}
		>
			{showFeedback ? (
				<EzSuccessFeedback message={feedbackMessage} />
			) : (
				<EzForm
					className="flex flex-col gap-6 mt-2 w-80"
					formData={formData}
					formName="order-form"
					onValidationChange={onOrderValidationChange}
					onSubmit={submitForm}
					key={clientResetId + 1}
				>
					<EzInput
						validators={[]}
						name="date"
						label="Data"
						placeholder="Data"
						type="date"
						value={formData.date}
						onChange={(e) => onDatePickerChange(e, 'date')}
						key={clientResetId + 2}
					/>
					<Autocomplete
						options={clients}
						getOptionLabel={(client) => client?.company ?? ''}
						className="w-full"
						value={formData?.client ?? null}
						onChange={onClientChange}
						onInputChange={() => console.log(formData)}
						renderInput={(params) => <TextField {...params} label="Cliente" name="client" />}
					/>
					<EzInput
						name="amount"
						label="Importo"
						type="amount"
						value={formData?.amount?.toString().replace('.', ',')}
						onChange={onChange}
						validators={[EzInputValidator.REQUIRED, EzInputValidator.AMOUNT, EzInputValidator.MORE_THAN_ZERO]}
						key={clientResetId + 4}
					/>
				</EzForm>
			)}
		</EzModal>
	);
}
