import { Add, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdQueryStats } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { EzCard } from '../../components/core/ez-card/EzCard';
import { EzHeading } from '../../components/core/ez-heading/EzHeading';
import { EzTable } from '../../components/core/ez-table/EzTable';
import { getAllRevenuesAction } from '../../features/revenues/revenues.actions';
import { useAppSelector, useModal } from '../../hooks';
import { EzTableColumn } from '../../om';
import { RevenueDTO } from '../../om/dto/revenues/revenue.dto';
import { EzTableColumnType } from '../../om/enum/ez-table-column-type.enum';
import { RevenueState } from '../../om/features/revenue.state';
import useCurrency from '../../utils/currency.util';
import { RevenueDiagram } from './revenue-diagram/RevenueDiagram';
import { RevenueFormModal } from './revenue-form-modal/RevenueFormModal';
import './revenue.scss';

export function Revenue() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { revenues, revenuesTableData }: RevenueState = useAppSelector((state: any) => state.revenues);
	const [revenueYearToEdit, setRevenueYearToEdit] = useState<number | undefined>(undefined);
	const { formatCurrency } = useCurrency();

	const [isRevenueFormModalOpen, openRevenueFormModal, closeRevenueFormModal] = useModal();

	useEffect(() => {
		dispatch(getAllRevenuesAction());
	}, []);

	const openRevenueEditFormModal = () => {
		setRevenueYearToEdit(getRevenueYears().pop());
		openRevenueFormModal();
	};

	const closeEditRevenueFormModal = () => {
		setRevenueYearToEdit(undefined);
		closeRevenueFormModal();
	};

	const onUpdateRevenueCallback = async () => {
		await dispatch(getAllRevenuesAction());
	};

	const renderHeaderButton = () =>
		revenues.length > 0 ? (
			<div className="flex gap-4">
				<Button
					onClick={openRevenueEditFormModal}
					startIcon={<Edit />}
					variant="outlined"
					color="primary"
					type="submit"
					size="large"
				>
					{t('REVENUE.EDIT_LABEL')}
				</Button>
				{renderAddRevenueButton(t('REVENUE.ADD_LABEL'))}
			</div>
		) : null;

	const renderAddRevenueButton = (label: string, size: any = 'large') => (
		<Button
			onClick={openRevenueFormModal}
			startIcon={<Add />}
			variant="contained"
			color="primary"
			type="submit"
			size={size}
		>
			{label}
		</Button>
	);

	const getRevenueYears = (): number[] => Array.from(new Set(revenues.map((item: RevenueDTO) => item.year)));

	const renderColumnAmount = (item: any, amountField: string) => {
		return <div className={`revenues__amount`}>{item[amountField] ? formatCurrency(item[amountField]) : '-'}</div>;
	};

	const getTableColumnSettings = (): Array<EzTableColumn> => {
		const revenueYears = getRevenueYears();
		return [
			{
				id: 'month',
				label: 'Mese',
				columnType: EzTableColumnType.TEXT,
			},
			...revenueYears.map(
				(year) =>
					({
						id: year.toString(),
						label: year.toString(),
						columnType: EzTableColumnType.CUSTOM,
						customColumn: (item) => renderColumnAmount(item, year.toString()),
					} as EzTableColumn)
			),
		];
	};

	const renderRevenueContent = () => (
		<Fragment>
			<RevenueDiagram />
			<EzTable id="revenues-list" columns={getTableColumnSettings()} data={revenuesTableData} isPaginated={false} />
		</Fragment>
	);

	const renderEmptyRevenuesLabel = () => (
		<div className="revenues__empty flex flex-col items-center mt-10">
			<MdQueryStats size="36" className="text-tsecondary" />
			<h3 className="text-md">{t('REVENUE.EMPTY.DESCRIPTION')}</h3>
			<div className="mt-2">{renderAddRevenueButton(t('REVENUE.NEW_LABEL'), 'medium')}</div>
		</div>
	);

	return (
		<Fragment>
			<div className="revenues__container mt-5">
				<EzCard>
					<EzHeading title={t('REVENUE.PAGE_TITLE')} contentEnd={renderHeaderButton()} />
					{revenues.length > 0 ? renderRevenueContent() : renderEmptyRevenuesLabel()}
				</EzCard>
			</div>
			<RevenueFormModal
				modalId="revenue-form-modal"
				isOpen={isRevenueFormModalOpen}
				revenueYearToEdit={revenueYearToEdit}
				revenueYears={getRevenueYears()}
				callback={onUpdateRevenueCallback}
				closeModalCallback={closeEditRevenueFormModal}
				isLoading={false}
			/>
		</Fragment>
	);
}
