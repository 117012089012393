export default function useCurrency(
	currencyDisplay = 'symbol',
	style: 'currency' | 'decimal' | 'percent' | 'unit' = 'currency'
) {
	const currency = 'EUR';

	const currencyFormatter = new Intl.NumberFormat('it-IT', {
		style,
		currency,
		currencyDisplay,
	});

	const compactCurrencyFormatter = new Intl.NumberFormat('en-US', {
		notation: 'compact',
		compactDisplay: 'short',
		style,
		currency,
	});

	const decimalFormatter = new Intl.NumberFormat('it-IT', {
		style: 'decimal',
		minimumFractionDigits: 2, // Always show 2 decimal places
		maximumFractionDigits: 2, // Cap at 2 decimal places
	});

	const formatCurrency = (value: number, style = 'currency'): string => {
		return currencyFormatter.format(value);
	};

	const formatCompactCurrency = (value: number) => {
		return compactCurrencyFormatter.format(value);
	};

	const formatDecimal = (value: number): string => {
		return decimalFormatter.format(value);
	};

	return { formatCurrency, formatCompactCurrency, formatDecimal };
}
