import { useTranslation } from 'react-i18next';
import { EzInputValidator } from '../../../om/enum/ez-input-validator.enum';

export interface EzErrorsLabelProps {
	data: any;
	validators: Array<EzInputValidator>;
}

export function EzErrorsLabel({ data, validators }: EzErrorsLabelProps) {
	const { t } = useTranslation();

	const composeErrorMessage = (errorMessageKey: string) => {
		return <span className="label-text-alt text-red-600">{t(`CORE.VALIDATORS.${errorMessageKey}`)}</span>;
	};

	const isAmountValid = (): boolean => {
		const REG_TO_CHECK_AMOUNT = /^\d*[,]*\d*$/;
		return REG_TO_CHECK_AMOUNT.test(data);
	};

	const isNumberBetterThanZero = () => {
		return data?.replace(',', '.') > 0;
	};

	return (
		<label className="label">
			{validators.includes(EzInputValidator.REQUIRED) && !data
				? composeErrorMessage('REQUIRED')
				: validators.includes(EzInputValidator.AMOUNT) && !isAmountValid()
				? composeErrorMessage('AMOUNT')
				: validators.includes(EzInputValidator.MORE_THAN_ZERO) && !isNumberBetterThanZero()
				? composeErrorMessage('MORE_THAN_ZERO')
				: null}
		</label>
	);
}
