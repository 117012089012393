import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EzForm } from '../../../components/core/ez-form/EzForm';
import { EzInput } from '../../../components/core/ez-input/EzInput';
import { EzModal } from '../../../components/core/ez-modal/EzModal';
import { EzSelect } from '../../../components/core/ez-select/EzSelect';
import { getAllRevenuesAction, updateRevenueAction } from '../../../features/revenues/revenues.actions';
import { useAppSelector, useModal } from '../../../hooks';
import { useToast } from '../../../hooks/toaster.hook';
import { BaseFormModal } from '../../../om';
import { RevenueDTO, RevenueFormDTO } from '../../../om/dto/revenues/revenue.dto';
import { EzInputValidator } from '../../../om/enum/ez-input-validator.enum';
import { RevenueState } from '../../../om/features/revenue.state';
import { DeleteRevenueModal } from '../delete-revenue-modal/DeleteRevenueModal';
import './revenue-form-modal.scss';

export interface RevenueFormModalProps extends BaseFormModal<RevenueDTO> {
	isLoading: boolean;
	revenueYears: number[];
	revenueYearToEdit?: number;
	callback: () => void;
}

export function RevenueFormModal({
	revenueYearToEdit,
	revenueYears,
	modalId,
	onConfirm,
	closeModalCallback,
	callback,
	isLoading,
	isOpen,
}: RevenueFormModalProps) {
	const { t } = useTranslation();
	const { revenues }: RevenueState = useAppSelector((state: any) => state.revenues);
	const initialFormData: RevenueFormDTO = {
		year: '',
		1: '',
		2: '',
		3: '',
		4: '',
		5: '',
		6: '',
		7: '',
		8: '',
		9: '',
		10: '',
		11: '',
		12: '',
	};
	const [isFormValid, setIsFormValid] = useState(true);
	const [isDeleteRevenueModalOpen, openDeleteRevenueModal, closeDeleteRevenueModal] = useModal();
	const [revenueYearToDelete, setRevenueYearToDelete] = useState<number | undefined>(undefined);
	const [formData, setFormData] = useState<any>(initialFormData);
	const [formResetId, setFormResetId] = useState(1);
	const dispatch = useDispatch();
	const toaster = useToast();

	useEffect(() => {
		if (revenueYearToEdit) {
			setFormData(getRevenueToEditDetail(revenueYearToEdit));
		} else setFormData(initialFormData);
	}, [revenueYearToEdit]);

	useEffect(() => {}, [isOpen]);

	useEffect(() => {
		if (formData.year !== '') setFormData(getRevenueToEditDetail(+formData.year));
	}, [formData.year]);

	const getRevenueToEditDetail = (yearToEdit: number | undefined): RevenueDTO => {
		const revenueToEdit: any = {
			year: `${yearToEdit}`,
		};

		for (let i = 1; i <= 12; i++) {
			revenueToEdit[i] = '';
		}
		setFormWithRevenueToEdit(yearToEdit, revenueToEdit);
		return revenueToEdit as RevenueDTO;
	};

	const setFormWithRevenueToEdit = (yearToEdit: number | undefined, revenueToEdit: any) => {
		revenues
			.filter((revenue: RevenueDTO) => revenue.year === yearToEdit)
			.forEach((revenueInEdit: RevenueDTO) => {
				revenueToEdit[revenueInEdit.month] = revenueInEdit.amount;
			});
	};

	const modalActions = () => (
		<Button form="revenue-form" id="confirm" type="submit" className="btn btn-primary w-32" disabled={!isFormValid}>
			{t('COMMON.CONFIRM')}
		</Button>
	);

	const submitForm = () => {
		createRevenue();
	};

	const createRevenue = async () => {
		const result = await dispatch(updateRevenueAction({ id: formData.year, body: getRevenueInputBody() }));
		if (result.error) toaster.error(t('REVENUE.ERRORS.GENERIC_CREATE'));
		else successCreateCallback(result.payload);
	};

	const getRevenueInputBody = () => ({
		revenues: Object.entries(formData)
			.map((entry: [string, unknown]) => {
				console.log(entry);
				if (entry[0] !== 'year')
					return { month: +entry[0], amount: entry[1] ? +`${entry[1]}`?.replace(',', '.') : null };
			})
			.filter((inputField) => !!inputField),
	});

	const successCreateCallback = async (result: any) => {
		closeModalAndReloadList();
		setFormData(initialFormData);
		toaster.success(t('REVENUE.TOOLTIP.CONFIRMED_CREATE') as string);
	};

	const closeModal = () => {
		closeModalCallback();
		setFormData(initialFormData);
		setFormResetId((key) => key + 1);
	};

	const closeModalAndReloadList = async () => {
		await dispatch(getAllRevenuesAction());
		closeModal();
	};

	const onChange = (e: any) => {
		setFormData((prevState: any) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const openDeleteRevenueWarning = (e: any) => {
		console.log(e);
		setRevenueYearToDelete(formData.year);
		openDeleteRevenueModal();
	};

	const onDeleteRevenueCallback = () => {
		closeModal();
		callback();
	};

	const resetDeleteModal = () => {
		closeDeleteRevenueModal();
		setRevenueYearToDelete(undefined);
	};

	const renderYearInput = () => (
		<div className="mb-2  w-full">
			{revenueYearToEdit ? (
				<div className="flex gap-4 items-center justify-between">
					<EzSelect
						label="Anno"
						name="year"
						validators={[EzInputValidator.REQUIRED]}
						value={formData.year}
						onChange={onChange}
						options={revenueYears.map((year) => ({
							value: year,
							description: year.toString(),
						}))}
					/>
					<Button
						id="delete-revenue-year"
						type="button"
						variant="contained"
						color="error"
						startIcon={<Delete />}
						size="large"
						onClick={openDeleteRevenueWarning}
					>
						{t('COMMON.DELETE')}
					</Button>
				</div>
			) : (
				<EzInput
					validators={[EzInputValidator.REQUIRED]}
					name="year"
					label="Anno"
					placeholder="Inserisci l'anno"
					type="text"
					value={formData.year}
					onChange={onChange}
				/>
			)}
		</div>
	);

	return (
		<Fragment>
			<EzModal
				id={modalId}
				title={revenueYearToEdit ? 'REVENUE.REVENUE_FORM_MODAL.EDIT_LABEL' : 'REVENUE.NEW_LABEL'}
				actions={modalActions()}
				closeCallback={closeModal}
				isOpen={isOpen}
			>
				<EzForm
					className="flex flex-col gap-6 mt-4"
					formData={formData}
					formName="revenue-form"
					onValidationChange={setIsFormValid}
					onSubmit={submitForm}
					key={formResetId + 1}
				>
					{renderYearInput()}
					<div className="revenue-form-modal__months flex flex-wrap gap-4">
						{moment.months().map((month, index: number) => (
							<Fragment>
								<EzInput
									name={`${index + 1}`}
									label={month}
									placeholder=""
									type="amount"
									validators={[EzInputValidator.AMOUNT]}
									value={`${formData[index + 1]}`.replace('.', ',')}
									onChange={onChange}
									key={formResetId + index + 1}
								/>
								{index === 5 ? <span className="mb-4" style={{ width: '100%' }}></span> : null}
							</Fragment>
						))}
					</div>
				</EzForm>
			</EzModal>
			<DeleteRevenueModal
				modalId="delete-target-modal"
				isOpen={isDeleteRevenueModalOpen}
				revenueYearToDelete={revenueYearToDelete}
				callback={onDeleteRevenueCallback}
				closeModalCallback={resetDeleteModal}
			/>
		</Fragment>
	);
}
