import moment from 'moment';
import { RevenueDTO } from '../../om/dto/revenues/revenue.dto';
import { RevenueState } from '../../om/features/revenue.state';
import { getAllRevenuesAction, updateRevenueAction } from './revenues.actions';

const getLabelledRevenues = (sortedRevenues: RevenueDTO[]) =>
	sortedRevenues.map((revenue: RevenueDTO) => ({
		...revenue,
		fullLabel: ` ${moment.months()[revenue.month - 1]} ${revenue.year}`,
		label: `${moment.months()[revenue.month - 1].substring(0, 3)}-${revenue.year}`,
	}));

const sortRevenues = (sortedRevenues: RevenueDTO[]) =>
	sortedRevenues.sort((a: RevenueDTO, b: RevenueDTO) => {
		if (a.year !== b.year) return a.year - b.year;
		return a.month - b.month;
	});

const convertRevenueDataToTableData = (revenues: RevenueDTO[]) =>
	revenues.reduce((acc: any, curr: any) => {
		if (Object.keys(acc).length === 0) {
			moment.months().forEach((monthName) => {
				acc[monthName] = { month: monthName.toUpperCase() };
			});
		}
		const monthName = moment.months()[curr.month - 1];
		acc[monthName][curr.year] = curr?.amount ?? 0.0;
		return acc;
	}, {});

export const getAllRevenuesBuilders = (builder: any): any => {
	let sortedRevenues = [];
	builder
		.addCase(getAllRevenuesAction.pending, (state: RevenueState) => {
			state.isGetAllRevenuesLoading = true;
		})
		.addCase(getAllRevenuesAction.rejected, (state: RevenueState, action: any) => {
			state.isGetAllRevenuesLoading = false;
		})
		.addCase(getAllRevenuesAction.fulfilled, (state: RevenueState, action: any) => {
			state.isGetAllRevenuesLoading = false;
			sortedRevenues = action.payload;
			sortedRevenues = getLabelledRevenues(sortedRevenues);
			sortRevenues(sortedRevenues);
			state.revenues = sortedRevenues;
			state.revenuesTableData = Object.values(convertRevenueDataToTableData(sortedRevenues));
		});
};

export const updateRevenueBuilders = (builder: any): any =>
	builder
		.addCase(updateRevenueAction.pending, (state: RevenueState) => {
			state.isUpdateRevenueSuccess = false;
		})
		.addCase(updateRevenueAction.rejected, (state: RevenueState, action: any) => {
			state.isUpdateRevenueSuccess = false;
		})
		.addCase(updateRevenueAction.fulfilled, (state: RevenueState, action: any) => {
			state.isUpdateRevenueSuccess = true;
		});
